<script setup lang="ts">
import type { SubscriptionType } from '@respell/database';
import {
  subscriptionTypeToDisplayName,
  subscriptionTypeToIconName,
} from '~/util/constants';

const workspaceStore = useWorkspaceStore();
const {
  workspaceId,
  subscriptionType,
  freeRuns,
  monthlyRuns,
  monthlyRunLimit,
} = storeToRefs(workspaceStore);

const subscriptionName = computed(
  () =>
    subscriptionTypeToDisplayName[subscriptionType.value as SubscriptionType],
);

const iconName = computed(
  () => subscriptionTypeToIconName[subscriptionType.value as SubscriptionType],
);

const atLimit = computed(
  () => monthlyRuns.value >= freeRuns.value + monthlyRunLimit.value,
);

const { pending } = useAsyncCache(
  `spellMeter/${workspaceId}`,
  () => workspaceStore.fetchSpellMeter(),
  {
    lazy: true,
  },
);

const showMeter = computed(() => monthlyRunLimit.value !== null);
</script>
<template>
  <USkeleton v-if="pending" class="h-16 w-full" />
  <div
    v-else-if="showMeter"
    class="hover:bg-gray-50 p-4 rounded hover:cursor-pointer"
    @click="navigateTo('/account/billing')"
  >
    <div class="flex justify-between">
      <div class="font-semibold mb-2">
        <UIcon :name="iconName" class="text-primary w-5 h-5" />
        {{ subscriptionName }}
      </div>
      <div class="dimmed mb-2">
        <p>{{ monthlyRuns }} / {{ monthlyRunLimit + freeRuns }}</p>
      </div>
    </div>
    <UMeter
      :value="monthlyRuns"
      :max="monthlyRunLimit + freeRuns"
      :color="atLimit ? 'red' : 'primary'"
    />
    <p v-if="atLimit" class="text-red-500 text-xs">
      You have reached your spell limit.
    </p>
  </div>
</template>
